<template>
  <div :class="b()" :style="styleSizeName" @mousewheel.prevent="handleMousewheel" v-loading.fullscreen.lock="fullscreenLoading">
    <div :ref="id" :style="styleChartName" v-if="isEchart"></div>
    <div :class="b('map')" v-else>
      <div
        :class="b('box')"
        :style="styleImgName"
        @mousedown="disabled ? false : handleMouseDown($event)"
        @mousemove="disabled ? false : handleMouseMove($event)"
        @mouseup="disabled ? false : handleMouseUp($event)">
        <img :class="b('bg')" :src="img" ref="img" draggable="false" />
        <span :class="b('location')" v-for="item in location" :key="item" :style="{ left: setPx(item.x), top: setPx(item.y) }"></span>
      </div>
    </div>
  </div>
</template>

<script>
import create from '../../create'
import { getMap } from '@/api/avueData/map'
import { encryptDes, decryptDes } from '@/utils/des'
export default create({
  name: 'map',
  data() {
    return {
      bannerCount: 0,
      bannerCheck: '',
      move: false,
      step: 1,
      startLeft: 0,
      startTop: 0,
      baseScale: 0,
      left: 0,
      top: 0,
      centerData: [],
      zoomData: 1,

      // 转换字符的map
      transDict: {
        "{and}": '&'
      },

      // 强制使用的区域代号
      forceAdCode: '',

      // 加载状态
      fullscreenLoading: false,

      // 是否切换区域
      isSwitchArea: true,

      // 提示的引用
      tootipRef: null

    }
  },
  watch: {
    mapData() {
      this.updateChart()
    },
    dataChartLen() {
      this.setBanner()
    },
    bannerTime() {
      this.setBanner()
    },
    banner: {
      handler() {
        this.setBanner()
      },
      immediate: true
    },
    type() {
      if (this.isEchart) {
        this.$nextTick(() => {
          this.init()
          this.updateData()
        })
      }
    },
    width() {
      this.updateData()
    },
    height() {
      this.updateData()
    },
    zoom: {
      handler() {
        this.zoomData = this.zoom
      },
      immediate: true
    },
    scale: {
      handler(val) {
        this.baseScale = val
      },
      immediate: true
    }
  },
  computed: {
    zoomShow() {
      return this.option.zoomShow || 1
    },
    zoom() {
      return this.option.zoom || 1
    },
    mapData() {
      return this.option.mapData || {}
    },
    borderWidth() {
      return this.option.borderWidth || 3
    },
    borderColor() {
      return this.option.borderColor || '#389BB7'
    },
    areaColor() {
      return this.option.areaColor || '#0c162f'
    },
    empColor() {
      return this.option.empColor || '#fff'
    },
    empAreaColor() {
      return this.option.empAreaColor || 'yellow'
    },
    color() {
      return this.option.color || '#fff'
    },
    fontSize() {
      return this.option.fontSize || 24
    },
    isEchart() {
      return this.type === 0
    },
    bannerTime() {
      return this.option.bannerTime || 3000
    },
    banner() {
      return this.option.banner
    },
    scale() {
      return this.option.scale || 100
    },
    styleImgName() {
      return {
        marginLeft: this.setPx(this.left),
        marginTop: this.setPx(this.top),
        transform: `scale(${this.baseScale / 100}, ${this.baseScale / 100})`
      }
    },
    location() {
      return this.option.location || []
    },
    img() {
      return this.option.img
    },
    type() {
      return this.option.type
    },
    locationData() {
      return (this.dataChart || []).map(ele => {
        ele.zoom = ele.zoom || 1
        const zoomData = this.zoomData < 1 ? 1 : this.zoomData
        return Object.assign(
          (() => {
            if (ele.zoom <= zoomData) {
              return {
                name: ele.name
              }
            }
            return {}
          })(), {
            value: [ele.lng, ele.lat, ele.value]
          }
        )
      })
    }
  },
  methods: {
    docMouseUp() {
      window.document.onmouseup = e => {
        window.document.onmousemove = undefined
        this.move = false
      }
    },
    handleMouseDown(e) {
      this.move = true
      this.startLeft = e.clientX
      this.startTop = e.clientY
      this.docMouseUp()
    },
    handleMouseMove(e) {
      if (this.move) {
        window.document.onmousemove = e => {
          const startX = e.clientX
          const startY = e.clientY
          this.left = this.left + (startX - this.startLeft) * this.step
          this.top = this.top + (startY - this.startTop) * this.step
          this.startLeft = startX
          this.startTop = startY
        }
      }
    },
    handleMouseUp() {
      this.move = false
    },
    handleMousewheel(e) {
      const type = e.deltaY
      if (type > 0) {
        this.baseScale = this.baseScale + 10
      } else {
        this.baseScale = this.baseScale - 10
      }
    },
    resetBanner() {
      this.$nextTick(() => {
        this.myChart.dispatchAction({
          type: 'hideTip'
        })
        // this.myChart.dispatchAction({
        //   type: "downplay"
        // });
      })
    },
    setBanner() {
      clearInterval(this.bannerCheck)
      if (this.banner) {
        this.bannerCheck = setInterval(() => {
          const curr = this.bannerCount % this.dataChartLen
          this.myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: '0',
            dataIndex: curr
          })
          this.myChart.dispatchAction({
            type: 'downplay'
          })
          this.myChart.dispatchAction({
            type: 'highlight',
            dataIndex: curr
          })
          this.bannerCount += 1
        }, this.bannerTime)
      }
    },
    updateChart() {

      const key = 'MAP_' + this.mapData

      // 如果sessionStorage有存储数据则恢复到window对象
      try {
        let geoMap = localStorage.getItem(key)
        if (geoMap != null) {
          geoMap = JSON.parse(decryptDes(geoMap))
          if (!geoMap.length || (Number(new Date().getTime()) - Number(geoMap[1]) > 86400000)) throw new Error('地图数据有误/或者时间太久,将会更新')
          window.mapChartData = {}
          window.mapChartData[key] = geoMap[0]
        }
      } catch (error) {
        console.log("localStorage -- 解析错误,将请求网络数据: ", error)
      }

      // 存储地图数据
      if (window.mapChartData && window.mapChartData[key]) {
        console.log('****** 使用缓存地图数据')
        this.updateSeries(window.mapChartData[key])
        return
      }

      console.log('****** 网络请求数据 - this.mapData: ', this.mapData)
      // 获取数据
      getMap(this.mapData).then(res => {
        const data = res.data.data
        // 克隆数据
        window.mapChartData = {}
        window.mapChartData[key] = data
        try {
          localStorage.setItem(key, encryptDes(JSON.stringify([data, new Date().getTime()])))
        } catch (error) {
          console.info("保存地图数据到localStorage发生错误.... [" + this.mapData + ']')
        }
        const optionData = this.deepClone(data)
        this.updateSeries(optionData)
      })
    },
    updateSeries(optionData) {
      this.$echarts.registerMap('HK', optionData)

      // --- 地图中的数据点 ---
      const series = (() => {
        // 获取对应的颜色
        const colorMap = this.option.dyanmicColor.split(',').map(item => {
          const splits = item.split('-')
          return { value: splits[0], color: splits[1] }
        })

        const datas = []

        for (const item of this.locationData) {
          const parseItem = JSON.parse(item.name)

          let itemColor = colorMap.find(item => item.value == (parseItem.projectStatus != undefined ? (Number(parseItem.projectStatus) + 2) : parseItem.type))

          itemColor = this.option.dyanmicColor && itemColor ? itemColor.color : this.option.pointColor

          // console.log('*********** type: ', parseItem.type, ' | projectStatus: ', Number(parseItem.projectStatus) + 2, ' | itemColor: ', itemColor, ' | colorMap', colorMap)
          datas.push({
            type: 'effectScatter',
            coordinateSystem: 'geo',
            showEffectOn: 'emphasis',
            rippleEffect: {
              brushType: 'fill',
              scale: 4
            },
            symbolSize: this.fontSize,
            hoverAnimation: true,
            data: [item],
            label: {
              show: this.option.disLabel,
              position: ['130%', '0'],
              fontSize: this.fontSize,
              color: this.color,
              formatter: params => {
                return params.name
              }
            },
            itemStyle: {
              color: itemColor
            },
            emphasis: {
              label: {
                show: true,
                fontSize: this.fontSize + 10,
                color: this.option.empColor
              },
              itemStyle: {
                color: itemColor,
              }
            }
          })
        }

        return datas
      })()

      // --- 地图选项 ---
      let option = {
        tooltip: (() => {
          return Object.assign(
            (() => {
              return {
                formatter: (() => {
                  // 如果存在格式化的方式
                  if (this.option.tipFormatter) {
                    return new Function('item', this.option.tipFormatter)
                  }
                  // 不存在
                  return item => {}
                })()
              }
            })(), {
              backgroundColor: this.option.tipBackgroundColor || 'rgba(0,0,0,1)',
              textStyle: {
                fontSize: this.option.tipFontSize,
                color: this.option.tipColor || 'red'
              }
            }
          )
        })(),
        toolbox: {
          show: true,
          feature: {
            restore: {
              show: this.option.restoreVis ? true : false,
              title: '恢复地图',
              iconStyle: {
                borderWidth: 1.5,
                borderColor: this.option.restoreColor || 'white'
              }
            }
          }
        },
        geo: Object.assign(
          (() => {
            const result = {}

            // 缩放限制
            if (this.option.zoomlimits) {
              const limits = JSON.parse(this.option.zoomlimits)
              result.scaleLimit = {
                min: limits[0],
                max: limits[1]
              }
            }

            // 中心数据
            if (!this.validatenull(this.centerData)) {
              result.center = this.centerData
            }

            // 如果选中了区域 区域显示高亮
            if (this.forceAdCode) {
              result.regions = this.getReigons()
            }

            return result
          })(), {
            map: 'HK',
            zoom: this.zoomData,
            layoutCenter: ['50%', '50%'],
            layoutSize: 1200,
            roam: this.option.isRoam,
            label: {
              emphasis: {
                show: false
              },
              show: true,
              fontSize: this.fontSize,
              color: this.color
            },
            left: this.option.gridX,
            top: this.option.gridY,
            right: this.option.gridX2,
            bottom: this.option.gridY2,
            emphasis: {
              label: {
                color: this.empColor
              },
              itemStyle: {
                areaColor: this.empAreaColor
              }
            },
            itemStyle: {
              borderWidth: this.borderWidth,
              borderColor: this.borderColor,
              areaColor: this.areaColor
            }
          }
        ),
        series: series
      }

      this.myChart.off('mouseover')
      this.myChart.off('mouseout')
      this.myChart.off('georoam')

      this.myChart.on('mouseover', () => {
        clearInterval(this.bannerCheck)
        this.resetBanner()
      })
      this.myChart.on('mouseout', () => {
        this.bannerCount = 0
        this.setBanner()
      })
      this.myChart.on('georoam', e => {
        const option = this.myChart.getOption()
        const geo = option.geo[0]
        this.centerData = geo.center
        this.zoomData = geo.zoom
        if (this.zoomData < 1) this.zoomData = 1
      })

      setTimeout(() => {

        // 地图点击事件
        this.myChart.on('click', params => {

          // console.log('点击事件: ', params, window.mapChartData['MAP_' + this.mapData])

          // 点击坐标点
          if (params.componentType === 'series') {
            let funBody = ""
            // 转换字符
            for (const key of Object.keys(this.transDict)) {
              funBody = this.option.pointClickEvent.replaceAll(key, this.transDict[key])
            }
            // 调用函数
            const pointClickFunc = new Function('params', funBody)
            pointClickFunc(params)
          }

          // 点击区域回调函数
          if (params.componentType === 'geo' && this.option.openAreaSelect) {

            // 提示限制
            if (this.isLimit()) {
              if (this.tootipRef) {
                this.tootipRef.close()
                this.tootipRef = null
              }
              this.tootipRef = this.$message.warning('没有权限查看')
              return
            }

            // 找到对应的数据
            const currRegion = window.mapChartData['MAP_' + this.mapData].features.find(item => item.properties.name === params.region.name)

            // 刷新页面
            const adcode = currRegion.properties.adcode
            const url = this.getUrl(adcode)
            // console.log('使用的链接的: ', url)
            this.openFullScreenLoading()

            setTimeout(() => {
              // window.location.href = url
              const params = { target: url }
              this.$router.push({ name: 'redirect', params })
            }, 500);

          }

        })

      }, 200);
      this.myChart.resize()
      this.myChart.setOption(option, true)
    },

    // 获取页面刷新的链接
    getUrl(adCode) {
      const path = this.$route.path
      const query = this.$route.query

      let params = []
      Object.keys(query).forEach(key => {
        if (key == 'adCode') return
        params.push(`${key}=${query[key]}`)
      })
      if (this.forceAdCode != adCode) params.push(`adCode=${adCode}`)

      params = params.join('&')

      return params == '' ? path : (path + '?' + params)
    },

    getReigons() {
      // console.log('当前使用: ', this.mapData, window.mapChartData['MAP_' + this.mapData], this.forceAdCode)
      const currRegion = window.mapChartData['MAP_' + this.mapData].features.find(item => item.properties.adcode == this.forceAdCode)
      // console.log('当前的区域: ', currRegion)
      if (!currRegion) return []

      return [{
        name: currRegion.properties.name, //区块名称
        label: {
          color: this.option.empAreaSelectedLabelColor || this.option.color
        },
        emphasis: {
          itemStyle: {
            areaColor: this.option.empAreaSelectedColor || this.empAreaColor
          }
        },
        itemStyle: {
          normal: {
            areaColor: this.option.empAreaSelectedColor || this.empAreaColor
          }
        }
      }]
    },

    openFullScreenLoading() {
      this.$loading({
        lock: true,
        text: '转到指定区域',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },

    // 在html中添加css
    loadCssCode(code) {
      const style = document.createElement('style')
      style.type = 'text/css'
      style.rel = 'stylesheet'
      try {
        // for Chrome Firefox Opera Safari
        style.appendChild(document.createTextNode(code))
      } catch (ex) {
        // for IE
        style.styleSheet.cssText = code
      }
      const head = document.getElementsByTagName('head')[0]
      head.appendChild(style)
    },

    /**
     * 判断是否限制选择区域选择
     */
    isLimit() {

      const regionCode = this.$store.state.user.regionCode

      const isBuild = window.location.href.match(/\/build\/\d+/)

      return !isBuild && regionCode != '1000'
    }

  },
  created() {

    // 限制点击区域跳转
    // 查看页面 并且 regionCode != 10000
    if (this.isLimit()) {
      // 存储的adCode
      const adcodeStore = this.$store.state.user.adcode
      // url中存在adcode
      const adCodeMatch = window.location.href.match(/adCode=(\d+)/)
      // 并且是当前用户的区域
      if (adCodeMatch && adCodeMatch[1] == adcodeStore) {
        this.forceAdCode = adcodeStore
        window.BIDATA.adCode = this.forceAdCode
        // 加载css隐藏返回省级
        this.loadCssCode(`
            div[name="返回省级"]{
              display: none;
            }
        `)
      } else {
        // 不是当前用户所能查看的区域
        this.$message.warning('区域代码无效,将会强制返回并刷新页面')
        this.$router.push({ name: 'home' })
        setTimeout(() => {
          window.location.href = '/home'
        }, 1000);
      }
      // url 
      return
    }

    // 开启区域选择
    const adCode = this.$route.query.adCode
    if (adCode) {
      if (typeof adCode === 'object') {
        this.forceAdCode = adCode[adCode.length - 1]
        return
      }
      this.forceAdCode = adCode
      // 将区域码存入window.BIDATA中
      window.BIDATA.adCode = this.forceAdCode
    }
  },

})
</script>
