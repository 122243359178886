<template>
  <div :class="b()" ref="main" @click="handleClick">
    <div ref="box" :class="b('box')">
      <a ref="text" :class="b('text')" :href="linkHref" :style="[styleName, styleSizeName]" :target="linkTarget">{{ dataChart.value }}</a>
    </div>
  </div>
</template>

<script>
import create from '../../create'
export default create({
  name: 'text',
  data() {
    return {
      check: '',
      date: new Date(),
      left: 0
    }
  },
  computed: {
    scroll() {
      return this.vaildData(this.option.scroll, false)
    },
    overScroll() {
      return this.vaildData(this.option.overScroll, false)
    },
    linkHref() {
      return this.option.linkHref || '#'
    },
    linkTarget() {
      return this.option.linkTarget || '_self'
    },
    step() {
      return this.option.step || 5
    },
    speed() {
      return this.option.speed || 100
    },
    lineHeight() {
      return this.option.lineHeight || 40
    },
    fontSize() {
      return this.option.fontSize || 30
    },
    split() {
      return this.option.split
    },
    textWidth() {
      const textLen = String(this.dataChart.value).length
      return textLen * this.fontSize
    },
    styleName() {
      const temp = {
        width: this.scroll ? this.setPx(this.textWidth) : 'auto',
        transform: 'translateX(' + this.left + 'px)',
        textAlign: this.option.textAlign,
        letterSpacing: this.setPx(this.split),
        textIndent: this.setPx(this.split),
        backgroundColor: this.option.backgroundColor,
        fontWeight: this.option.fontWeight || 'normal',
        fontSize: this.fontSize + 'px',
        lineHeight: this.lineHeight + 'px',
        color: this.option.color || '#333',
        'text-shadow': this.option['textShadow'] || '',
      }
      if (this.option.overflowAuto) {
        temp['white-space'] = 'pre-wrap'
        temp['overflow'] = 'auto'
      }

      return temp
    }
  },
  watch: {
    overScroll() {
      this.move()
    },
    scroll() {
      this.move()
    },
    speed() {
      this.move()
    }
  },
  created() {
    setInterval(() => {
      this.date = new Date()
    }, 1000)
  },
  mounted() {
    this.move()
  },
  methods: {
    handleClick() {
      this.updateClick({
        value: this.dataChart.value
      })
      this.clickFormatter &&
        this.clickFormatter({
            data: this.dataChart
          },
          this.getItemRefs()
        )
    },
    move() {
      clearInterval(this.check)
      if (this.scroll) {
        this.check = setInterval(() => {
          // console.log('overScroll: ', this.overScroll)
          // console.log('textWidth: ', this.textWidth, ' | width: ', this.width, ' | this.dataChart.value:', this.dataChart.value, ' | this.fontSize:', this.fontSize)
          if ((this.textWidth * 0.8 < this.width - 10) && this.overScroll) {
            this.left = 0
            return
          }
          if (this.left < -(this.width + 5)) {
            this.left = this.width - 10
          }
          this.left = this.left - this.step
        }, this.speed)
      } else {
        this.left = 0
      }
    }
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.avue-echart-text__text {
  white-space: nowrap;
}
</style>
