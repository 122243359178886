<template>
  <div :class="b()" :style="styleSizeName">
    <el-row
      :style="noDataTipStyle"
      v-show="istip"
      type="flex"
      justify="center"
      align="middle">
      <div>暂无数据</div>
    </el-row>
    <div v-show="!istip" :ref="id" :style="styleChartName"></div>
  </div>
</template>

<script>
import create from "../../create";
import { sample } from 'lodash'

export default create({
  name: "bar",

  data() {
    return {
      noDataTipStyle: {
        'color': 'rgb(110, 212, 255)',
        'height': '100%'
      },
      istip: this.option.noDataTip
    }
  },

  mounted() {
    if (this.nameSpace) {
      window.BIDATA.BINS[this.nameSpace] = this
    }
    console.log('是否提示: ', this.istip)
  },
  computed: {
    x2() {
      return this.option.gridX2 || 20;
    },
    nameSpace() {
      return this.option.nameSpace
    }

  },
  methods: {
    updateChart(data) {
      // console.log('option',this.option);
      const optionData = this.deepClone(data || this.dataChart);
      const option = {
        title: this.ishasprop(this.option.titleShow, {
          text: this.option.title,
          subtext: this.option.subtext || '',
          textStyle: {
            color: this.option.titleColor || '#333',
            fontSize: this.option.titleFontSize || 16
          },
          left: this.option.titlePostion || 'auto',
          subtextStyle: {
            color: this.option.subTitleColor || '#aaa',
            fontSize: this.option.subTitleFontSize || 14
          }
        }, {}),
        // 鼠标移入的提示信息
        tooltip: (() => {
          const result = {}

          // 字体样式
          result.textStyle = {
            fontSize: this.option.tipFontSize,
            color: this.option.tipColor || "#fff"
          }

          // 使用了字数限制
          if (this.option.xTitleLimit && this.option.xTitleLimit != 0) {
            result.formatter = item => {
              const dataIndex = item.dataIndex
              const seriesIndex = item.seriesIndex
              const categorie = optionData.categories[dataIndex]
              const seriesValue = optionData.series[seriesIndex].data[dataIndex]
              const tip = `<div style="width: 10px;height: 10px;background-color: ${result.textStyle.color};display: inline-block;border-radius: 50%"></div>`
              // 格式化模板
              return `<div style="margin-bottom: 5px">${item.seriesName}</div> 
                      <div style="display:flex; align-items: center">${tip} <div style="width: 7px; height: 10px; display: inline-block"></div>  ${categorie} <div style="width: 15px; height: 10px; display: inline-block"></div>  <b>${seriesValue}</b></div>`
            }
          }

          // 结果
          return result
        })(),
        grid: {
          left: this.option.gridX || 20,
          top: this.option.gridY || 60,
          right: this.x2,
          bottom: this.option.gridY2 || 60
        },
        legend: {
          show: this.vaildData(this.option.legend, false),
          orient: this.option.legendOrient || "vertical",
          x: this.option.legendPostion || "left",
          top: 0,
          right: this.x2,
          textStyle: {
            fontSize: this.option.legendFontSize || 12,
          },
          data: (() => {
            return (optionData.series || []).map((ele, index) => {
              return {
                name: ele.name,
                textStyle: this.ishasprop(!this.switchTheme, {
                  color: this.getColor(index, true)
                }, {})
              };
            });
          })()
        },
        xAxis: {
          axisTick: {
            show: !this.option.xAxisShowTick
          },
          type: this.option.category ? "value" : "category",
          name: this.option.xAxisName,
          axisLine: {
            show: !this.option.xAxisShowTick,
            lineStyle: {
              color: this.option.lineColor || "#333"
            }
          },
          data: (() => {
            if (this.option.xTitleLimit && this.option.xTitleLimit != 0) {
              const temp = []
              optionData.categories.forEach(item => {
                const itemStr = String(item)
                if (itemStr.length > this.option.xTitleLimit) {
                  temp.push(itemStr.substring(0, this.option.xTitleLimit) + '...')
                } else {
                  temp.push(item)
                }
              })
              return temp
            }
            return optionData.categories || []
          })(),
          inverse: this.vaildData(this.option.xAxisInverse, false),
          show: this.vaildData(this.option.xAxisShow, true),
          splitLine: {
            show: this.vaildData(this.option.xAxisSplitLineShow, false),
            lineStyle: {
              color: this.option.lineColor || "#333"
            },
          },
          axisLabel: {
            interval: this.option.xAxisinterval || 'auto',
            rotate: this.option.xAxisRotate || 0,
            textStyle: {
              color: this.option.nameColor || "#333",
              fontSize: this.option.xNameFontSize || 14
            }
          }
        },
        yAxis: {
          type: this.option.category ? "category" : "value",
          name: this.option.yAxisName,
          data: optionData.categories || [],
          axisLabel: {
            textStyle: {
              color: this.option.nameColor || "#333",
              fontSize: this.option.yNameFontSize || 14,
              verticalAlign: this.option.yAxisLabelVerticalAlign || "top",
              align: this.option.yAxisLabelAlign || "left",
              padding: this.option.yAxisLabelPadding || [-28, 8, 20, 10],
            }
          },
          axisLine: {
            lineStyle: {
              color: this.option.lineColor || "#333"
            },
            show: this.vaildData(this.option.yAxisAxisLineShow, true)
          },
          inverse: this.vaildData(this.option.yAxisInverse, false),
          show: this.vaildData(this.option.yAxisShow, true),
          splitLine: {
            lineStyle: {
              color: this.option.lineColor || "#333",
              type: this.option.yAxisAxisTickShowType || 'soild'
            },
            show: this.vaildData(this.option.yAxisSplitLineShow, true)
          },
          axisTick: {
            show: this.vaildData(this.option.yAxisAxisTickShow, true)
          }
        },
        series: (() => {
          const barColor = this.option.barColor || [];
          const list = (optionData.series || []).map((ele, index) => {
            return Object.assign(ele, {
              type: "bar",
              stack: ele.stack,
              barWidth: this.option.barWidth || 16,
              barMinHeight: this.option.barMinHeight || 0,
              itemStyle: this.ishasprop(!this.switchTheme, {
                color: this.getColor(index)
              }, { barBorderRadius: this.option.barRadius || 0 }),
              label: {
                show: this.vaildData(this.option.labelShow, false), //开启显示
                position: this.option.category ? "right" : "top", //在上方显示,
                formatter: name => this.getLabelFormatter(name),
                textStyle: {
                  //数值样式
                  fontSize: this.option.labelShowFontSize || 14,
                  color: this.option.labelShowColor || "#333",
                  fontWeight: this.option.labelShowFontWeight || 500
                }
              },

            });
          });
          return list;
        })()
      };
      this.myChart.resize();
      this.myChart.setOption(option, true);
    },

    // 命名空间的值发生改变
    updateNsData() {
      this.updateChart(this.BINAMESPACE[this.nameSpace].data)
    }
  },
  watch: {
    dataChart(dataChart) {
      if (!this.option.noDataTip) {
        return
      }
      this.istip = (!dataChart || !dataChart.categories || dataChart.categories.length == 0 || !dataChart.series || dataChart.series.length == 0) && this.option.noDataTip
    }

  }
});
</script>
